// Text utils

"use strict";

/**
 * Removes acents and diacritics.
 * @param str   The input string
 * @returns     The normalized string
 */
export function normalizeString(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Escapes html reserved characters.
 * @param html      Input HTML text.
 * @returns         The escaped text.
 */
export function escapeHTML(html: string): string {
    return ("" + html)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apos;")
        .replace(/\//g, "&#x2f;")
        .replace(/'/g, "&#039;");
}

/**
 * Turns HTML into text (removes all tags)
 * @param html HTML
 * @returns Text
 */
export function htmlToText(html: string): string {
    return (html + "").replace(/<[^>]*>/g, "").trim();
}

/**
 * Escapes single quotes and reverse bars
 * @param raw The raw input text
 * @returns The escaped text.
 */
export function escapeSingleQuotes(raw: string): string {
    return ("" + raw).replace(/\\/g, "\\\\").replace(/'/g, "\\'");
}

/**
 * Escapes double quotes and reverse bars.
 * @param raw The raw input text
 * @returns The escaped text.
 */
export function escapeDoubleQuotes(raw: string): string {
    return ("" + raw).replace(/"/g, '\\"').replace(/\\/g, "\\\\");
}

export function generateSearchHighlights(text: string, query: string, cls?: string): string {
    if (!query) {
        return escapeHTML(text);
    }
    let result = "";
    let tmp = text;
    const queryLowerCase = query.toLowerCase();
    while (tmp.length > 0) {
        const i = tmp.toLowerCase().indexOf(queryLowerCase);
        if (i === -1) {
            result += escapeHTML(tmp);
            tmp = "";
        } else {
            const preQuery = tmp.substr(0, i);
            const q = tmp.substr(i, query.length);
            tmp = tmp.substr(i + query.length);
            result += escapeHTML(preQuery) + '<b class="' + (cls || "shl") + '">' + escapeHTML(q) + '</b>';
        }
    }
    return result;
}
