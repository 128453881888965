<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Logout") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t("Do you want to log out of the application?") }}</p>
                    <p v-if="anonymousUser && !loggedIn" class="info-anon">{{ $t("You have a guest account. If you continue, your data will be deleted.") }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="logout">
                        <i class="fas fa-sign-out-alt"></i> {{ $t("Logout") }}
                    </button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { CallsController } from "@/control/call";

export default defineComponent({
    name: "LogoutModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            profileName: AuthController.ProfileName || AuthController.anonName || "",
            anonymousUser: AuthController.isAnon(),
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        logout: function () {
            this.close();
            if (CallsController.selfUserStream) {
                CallsController.leaveCall();
            }
            const router = this.$router;
            AuthController.Logout().then(() => {
                router.push({ name: "login" });
            });
        },
        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.anonName || "";
            this.anonymousUser = AuthController.isAnon();
            this.profileImage = AuthController.ProfileImage;
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style scoped>
    .info-anon {
        color: var(--red-maya);
    }
</style>
