<template>
   <div>
        <div v-if="loading" class="modal-form-loading">{{ $t("Loading") }}...</div>
        <div v-if="!loading" class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>{{ $t("Template") }}</th>
                    <th>{{ $t("Title") }}</th>
                    <th>{{ $t("Description") }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="room in templates" :key="room.id">
                        <td>
                        <div class="w-35">
                            <img v-if="room.picture" class="img-fluid" :src="room.picture" :alt="room.name">
                            <div v-if="!room.picture" class="img-fluid-empty">{{getFirstChar(room.name)}}</div>
                        </div>
                        </td>
                        <td class="bold w-10">{{room.name}}</td>
                        <td class="template-room-description w-35">{{room.description}}</td>
                        <td class="buttons-template w-25">
                            <button type="button" class="btn btn-primary btn-sm mb-2 me-2" @click="openRoomLink(room.id)"><i class="fa-solid fa-eye"></i><span class="text-responsive">{{ $t("View") }}</span></button>
                            <button type="button" class="btn btn-cancel btn-sm mb-2" @click="useTemplate(room)"><i class="fa-solid fa-plus"></i><span class="text-responsive">{{ $t("Use template") }}</span></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";

import { ApiRoom } from "@/api/api-group-room";

import { getUniqueStringId } from "@/utils/unique-id";

export default defineComponent({
    components: {},
    name: "RoomsTemplatesListForm",
    emits: ["selected"],
    setup() {
        return {
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loading: false,

            templates: [],

            errorMessage: "",
        };
    },
    methods: {
        load: function () {
            this.templates = [];
            this.errorMessage = "";
            this.loading = true;

            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            Request.Pending(this.loadRequestId, ApiRoom.GetRoomsTemplates())
                .onSuccess((response) => {
                    this.loading = false;
                    this.templates = response;
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        temporalError: () => {
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },

        getRoomLink: function (rid: string) {
            return location.protocol + "//" + location.host + "/room/" + encodeURIComponent(rid) + "?s=single";
        },

        openRoomLink: function (rid: string) {
            const url = this.getRoomLink(rid);
            window.open(url, '_blank');
        },

        getFirstChar: function (name: string) {
            return name.substr(0, 2) || "??";
        },

        useTemplate: function (room) {
            this.$emit("selected", room);
        },
    },
    mounted: function () {
        this.load();
    },
    beforeUnmount: function () {
        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
});
</script>

<style scoped>
</style>
