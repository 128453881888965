<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus" @keydown="escapeToClose">
        <div class="modal-dialog modal-md h-50" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t("Create new organization") }}</div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body d-flex">
                    <div class="right-content">
                        <label class="headline-modal">{{ $t("Organization name") }}</label>
                        <input type="text" name="room-title" autocomplete="off" v-model="organizationName"
                            class="form-control" maxlength="80" @input="markDirty" />
                        <label class="headline-modal">{{ $t("Organization description") }}</label>
                        <textarea name="room-description" autocomplete="off" v-model="organizationDescription"
                            class="form-control" maxlength="255" @input="markDirty">
                            </textarea>
                        <label class="headline-modal">{{ $t("Website (URL)") }}</label>
                        <input type="text" name="website" autocomplete="off" v-model="organizationUrl"
                            class="form-control" maxlength="255" @input="markDirty" />
                        <div v-if="error" class="error-message">{{ error }}</div>
                        <div class="btn-bottom-modal d-flex justify-content-end align-items-center mt-3">
                            <button type="button" :disabled="busy" class="btn btn-primary btn-mr"
                                @click="createOrganization">
                                <i class="fa-solid fa-plus"></i><span class="text-responsive">{{ $t("Create") }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Request } from "@asanrom/request-browser";

import { useVModel } from "@/utils/v-model";

import { NewOrganizationRequest } from "@/api/definitions";
import { ApiOrganization } from "@/api/api-group-organization";

export default defineComponent({
    name: "CreateOrganizationModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            organizationName: "",
            organizationDescription: "",
            organizationUrl: "",
            busy: false,
            error: "",
            dirty: false,
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.displayStatus = false;
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        createOrganization: function () {
            if (this.busy) {
                return;
            }

            if (this.checkError()) {
                return;
            }

            this.busy = true;

            const body: NewOrganizationRequest = {
                name: this.organizationName,
                description: this.organizationDescription,
                url: this.organizationUrl,
            };

            Request.Do(ApiOrganization.PostOrganization(body))
                .onSuccess((response) => {
                    this.busy = false;
                    this.dirty = false;
                    this.$router.push({ name: "organization-configuration", params: { organizationId: response.id } });
                    this.$showSnackBar(this.$t("Organization added to the list"));
                    this.close();
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        badRequestTooMany: () => {
                            this.busy = false;
                            this.error = this.$t("Too many organizations");
                        },
                        badRequestInvalidUrl: () => {
                            this.busy = false;
                            this.error = this.$t("Invalid URL");
                        },
                        badRequestInvalidDescription: () => {
                            this.busy = false;
                            this.error = this.$t("Invalid description");
                        },
                        badRequestInvalidName: () => {
                            this.busy = false;
                            this.error = this.$t("Invalid name");
                        },
                        badRequest: () => {
                            this.busy = false;
                            this.error = this.$t("Bad request");
                        },
                        unauthorized: () => {
                            this.busy = false;
                            this.error = this.$t("Unauthorized");
                        },
                        temporalError: () => {
                            this.busy = false;
                            this.error = this.$t("Temporal error");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.busy = false;
                    console.error(err);
                    this.error = err.message;
                });
        },

        checkError: function () {
            if (!this.organizationName) {
                this.error = this.$t("Organization name is required");
                return true;
            }
            return false;
        },
        reset: function () {
            this.error = "";
            this.organizationName = "";
            this.organizationDescription = "";
            this.organizationUrl = "";
        },

        markDirty: function () {
            this.dirty = true;
        },

    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }

    },
    beforeUnmount: function () {
    },
});
</script>
<style scoped>
@import "@/style/modals/create-room.css";
</style>
