<template>
    <div class="side-bar" :class="{ mini: !expanded }">
        <div class="head-logo" :class="{ mini: !expanded }">
            <a v-if="expanded && logo" @click="goHome" class="cursor-pointer">
                <img class="logo" :src="logo">
            </a>
            <a v-else-if="expanded && !logo && theme === 'light'" @click="goHome" class="cursor-pointer">
                <img class="logo" src="/svg/maya-Interactivo.svg">
            </a>
            <a v-else-if="expanded && !logo && theme === 'dark'" @click="goHome" class="cursor-pointer">
                <img class="logo" src="/svg/maya-Interactivo_cb.svg">
            </a>
            <div class="min-side-bar-container" :class="{ mini: !expanded }">
                <button type="button" class="min-side-bar d-flex justify-content-center align-items-center cursor-pointer"
                    :title="$t('Main menu')" @click="clickMenu">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
        </div>
        <div class="side-bar-body" :class="{ mini: !expanded }">
            <a v-if="loggedIn" class="side-bar-option cursor-pointer" :class="{ selected: page === 'maybots' }" :title="$t('ChatBots')" @click="showChatbots">
                <div class="side-bar-option-icon"><i class="fa-solid fa-robot"></i></div>
                <div class="side-bar-option-text" v-if="expanded">{{ $t("ChatBot") }}</div>
            </a>

            <a class="side-bar-option cursor-pointer" :class="{ selected: page === 'showall' }" :title="$t('Show All')" @click="showAllRooms">
                <div class="side-bar-option-icon"><i class="fa-solid fa-list-ul"></i></div>
                <div class="side-bar-option-text" v-if="expanded">{{ $t("Show All") }}</div>
            </a>

            <a v-if="loggedIn" class="side-bar-option cursor-pointer" :class="{ selected: page === 'myrooms' }" :title="$t('My rooms')" @click="showMyRooms">
                <div class="side-bar-option-icon"><i class="fa-solid fa-house"></i></div>
                <div class="side-bar-option-text" v-if="expanded">{{ $t("My rooms") }}</div>
            </a>

            <a v-if="loggedIn" class="side-bar-option cursor-pointer" :class="{ selected: page === 'favorite' }" :title="$t('Favorite')" @click="showFavourites">
                <div class="side-bar-option-icon"><i class="fa-solid fa-star"></i></div>
                <div class="side-bar-option-text" v-if="expanded">{{ $t("Favorite") }}</div>
            </a>

            <a v-if="loggedIn" class="side-bar-option cursor-pointer" :class="{ selected: page === 'activity' }" :title="$t('Activity')" @click="showActivity">
                <div class="side-bar-option-icon"><i class="fa-solid fa-bell"></i></div>
                <div class="side-bar-option-text" v-if="expanded">{{ $t("Activity") }}</div>
            </a>
        </div>

        <footer class="container">
            <div class="top-footer-container" v-if="expanded">
                <input class="form-check-input cursor-pointer" :title="$t('Switch between light and dark theme')" type="checkbox"
                    role="switch" id="flexSwitchCheckDefault" @click="invertTheme">

                <button type="button" class="side-bar-button language-button cursor-pointer" :title="$t('Select your language')"
                    @click="selectLanguage">
                    <i class="fas fa-language"></i>
                </button>
            </div>
            <div class="login-side-bar">
                <button type="button" @click="login" class="login-btn cursor-pointer" v-if="!loggedIn && !isAnonymousUser">
                    <i class="fas fa-sign-in"></i>
                    <span v-if="expanded"> {{ $t("Login") }} </span>
                </button>
                <button type="button" @click="logout" class="login-btn cursor-pointer" v-else>
                    <i class="fas fa-sign-in"></i>
                    <span v-if="expanded"> {{ $t("Logout") }} </span>
                </button>
                <div v-if="loggedIn && !isAnonymousUser" class="dropdown user-config-button w-100 p-0 h-100">
                    <button class="dropdown-toggle side-bar-user h-100 cursor-pointer" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa-regular fa-circle-user"></i>
                        <span class="top-bar-hello-message" v-if="expanded">
                            {{ $t("$USER").replace("$USER", renderName(profileName)) }}
                        </span>
                    </button>
                    <ul class="dropdown-menu user-menu">
                        <li class="row ps-3 cursor-pointer ">
                            <div class="col-9 d-flex align-items-center user-header" @click="showSettings">
                                <i class="fa-regular fa-circle-user"></i>
                                <span class="top-bar-hello-message">{{ $t("$USER").replace("$USER", renderName(profileName)) }}</span>
                            </div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a :title="$t('Settings')" @click="showSettings" class="cursor-pointer">
                                <div class="dropdown-item">{{ $t("Settings") }}</div>
                            </a>
                        </li>
                        <li>
                            <a :title="$t('Organizations')" @click="showOrganizations" class="cursor-pointer">
                                <div class="dropdown-item">{{ $t("Organizations") }}</div>
                            </a>
                        </li>
                        <!-- <li><a class="dropdown-item " href="#">{{ $t("Downloads") }}</a></li> -->
                    </ul>
                </div>

                <div class="side-bar-user not-pointer" v-else>
                    <i class="fa-regular fa-circle-user"></i>
                    <span v-if="expanded">
                            {{ $t("$USER").replace("$USER", renderName(profileName)) }}
                        </span>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { ExitPreventer } from "@/utils/exit-preventer";

import { ColorThemeName, AppPreferences} from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { RoomController } from '@/control/room';

export default defineComponent({
    name: "MenuSideBar",
    emits: ["update:expanded", "toggle-menu", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            page: "",
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: AppPreferences.Theme,
            profileName: AuthController.ProfileName || AuthController.Username || AuthController.anonName || "",
            isAnonymousUser: AuthController.isAnon(),

            roomId: "",
            logo: "",
            isBackground: false,
            isGuest: false
        };
    },
    methods: { 
        logout: function () {
            this.$emit("openModal", "logout");
            this.onAuthChanged();
        },

        close: function () {
            this.displayStatus = false;
        },

        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },

        clickMenu: function () {
            this.$emit("toggle-menu");
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            AppPreferences.SetTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || AuthController.anonName || "";
            this.isAnonymousUser = AuthController.isAnon();
            this.profileImage = AuthController.ProfileImage;
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || this.$t("Guest");
        },

        onRoomMetaUpdated: function (roomId, meta) {
            this.roomId = roomId;
            if (meta) {
                this.logo = meta.logo;
                this.isBackground = meta.background !== "";
            }else {
                this.logo = "";
                this.isBackground = false;
            }
        },

        showSettings: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "settings", query: { tab: "profile" } });
            });
        },

        showOrganizations: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "organization" });
            });
        },

        showChatbots: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "maybots" });
            });
        },

        showAllRooms: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "showall" });
            });
        },

        showMyRooms: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "myrooms" });
            });
        },

        showFavourites: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "favorite" });
            });
        },

        showActivity: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "activity" });
            });
        },

        goHome: function() {
            ExitPreventer.handlePrevent("exit-room", () => {
                RoomController.leaveRoom();
                this.$router.push({ name: "home" });
            });
        }
    },
    mounted: function () {
        this.updatePage();

        this.$options.roomMetaHandler = this.onRoomMetaUpdated.bind(this);
        RoomController.on("room-meta", this.$options.roomMetaHandler);
        this.onRoomMetaUpdated(RoomController.room, RoomController.meta);

        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
    },
    beforeUnmount: function() {
        RoomController.removeEventListener("room-meta", this.$options.roomMetaHandler);
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style scoped>
@import "@/style/layout/side-bar.css";
</style>
